<template>
  <app-page :toolbar="toolbar" :list="list" @loaded="onLoaded">
    <template #append>
      <el-alert
        class="alert"
        :title="balancelTitle"
        type="success"
        :closable="false"
      />
    </template>
  </app-page>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'Stats',
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '80px',
        igrone: ['canteenType', 'dateType'],
        model: {
          name: undefined,
          departmentId: undefined,
          telephone: undefined,
          policeCard: undefined,
          canteenType: Number(Number(sessionStorage.getItem('canteenType')) !== 999 ? sessionStorage.getItem('canteenType') : 1),
          consumerGroupId: undefined,
          startDate: dayjs()
            .startOf('month')
            .format('YYYY-MM-DD'),
          endDate: dayjs()
            .endOf('month')
            .format('YYYY-MM-DD'),
          reservationType: undefined,
          dateType: 1
        },
        fields: [
          {
            label: '姓名',
            prop: 'name',
            placeholder: '请输入要搜索的姓名',
            clearable: true
          },
          {
            label: '部门',
            prop: 'departmentId',
            placeholder: '请选择要搜索的部门',
            type: 'cascader',
            clearable: true,
            collapseTags: true,
            filterable: true,
            props: {
              expandTrigger: 'hover',
              multiple: true,
              label: 'name',
              value: 'id'
            },
            options: [],
            url: '/department/getList',
            method: 'post'
          },
          {
            label: '电话',
            prop: 'telephone',
            placeholder: '请输入要搜索的电话',
            clearable: true
          },
          {
            label: '警号',
            prop: 'policeCard',
            placeholder: '请输入要搜索警号',
            clearable: true
          },
          {
            label: '食堂',
            prop: 'canteenType',
            placeholder: '请选择要搜索食堂',
            type: 'select',
            // clearable: true,
            options: [
              { label: '全部', value: undefined },
              { label: '一食堂', value: 1 },
              { label: '二食堂', value: 2 }
            ]
          },
          {
            label: '分组',
            prop: 'consumerGroupId',
            placeholder: '请选择要搜索分组',
            type: 'select',
            clearable: true,
            options: [],
            url: '/canteenConsumerGroup/dropDownList',
            mapper(item) {
              return {
                label: item.name,
                value: item.id
              };
            }
          },
          {
            label: '统计日期',
            prop1: 'startDate',
            prop2: 'endDate',
            type: 'daterange',
            valueFormat: 'yyyy-MM-dd',
            format: 'yyyy-MM-dd',
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期',
            pickerOptions: {
              firstDayOfWeek: 1
            }
          },
          {
            label: '统计类型',
            prop: 'reservationType',
            placeholder: '请选择要搜索预约类型',
            type: 'select',
            clearable: true,
            multiple: true,
            valueKey: 'value',
            options: [
              { label: '订餐预约', value: 0 },
              { label: '临时预约', value: 1 },
              { label: '业务订餐', value: 4 },
              { label: '外卖预约', value: 3 }
            ]
          }
        ],
        downloader: {
          text: '导出',
          url: '/canteen/expensesRecord/expensesRecordExportV2',
          method: 'post'
        }
      },
      list: {
        mode: 'all',
        url: '/canteen/expensesRecord/expensesRecordStatisticsV2',
        method: 'post',
        fields: [
          {
            label: '类型',
            prop: 'type',
            width: 180,
            headerAlign: 'center',
            colors: ['', 'success', 'danger', 'warning', 'primary'],
            formatter(row) {
              const values = ['', '早餐', '中餐', '晚餐', '外卖'];
              return values[row.type];
            }
          },
          {
            label: '次数',
            prop: 'number',
            width: 180,
            align: 'right',
            headerAlign: 'center'
          },
          {
            label: '扣除补贴',
            prop: 'deductSubsidySurplus',
            width: 180,
            align: 'right',
            headerAlign: 'center',
            formatter(row) {
              return `￥${row.deductSubsidySurplus}`;
            }
          },
          {
            label: '扣除现金',
            prop: 'deductCashSurplus',
            width: 180,
            align: 'right',
            headerAlign: 'center',
            formatter(row) {
              return `￥${row.deductCashSurplus}`;
            }
          },
          {
            label: '优惠金额',
            prop: 'preferentialAmount',
            width: 180,
            align: 'right',
            headerAlign: 'center',
            formatter(row) {
              return `￥${row.preferentialAmount}`;
            }
          },
          {
            label: '总计',
            prop: 'total',
            width: 180,
            align: 'right',
            headerAlign: 'center',
            formatter(row) {
              return `￥${row.total}`;
            }
          }
        ],
        action: false,
        pages: false
      },
      balance: {
        number: 0,
        deductSubsidySurplus: 0.0,
        deductCashSurplus: 0.0,
        preferentialAmount: 0.0,
        total: 0.0
      }
    };
  },
  computed: {
    balancelTitle() {
      const {
        number,
        deductSubsidySurplus,
        deductCashSurplus,
        preferentialAmount,
        total
      } = this.balance;
      return `次数:${number}，
      扣除补贴:￥${deductSubsidySurplus.toFixed(2)}，
      扣除现金:￥${deductCashSurplus.toFixed(2)}，
      优惠金额:￥${preferentialAmount}，总计:￥${total +
        preferentialAmount}(含优惠金额)`;
    }
  },
  methods: {
    onLoaded(e) {

      if (sessionStorage.getItem('canteenType')){
        if (Number(sessionStorage.getItem('canteenType')) === 1){
          this.toolbar.fields[4].options = [{ label: '一食堂', value: 1 }]
        }else if (Number(sessionStorage.getItem('canteenType')) === 2){
          this.toolbar.fields[4].options = [{ label: '二食堂', value: 2 }]
        }
      }

      this.balance = e.data.reduce(
        (a, b) => {
          return {
            number: parseInt(a.number) + parseInt(b.number),
            deductSubsidySurplus:
              a.deductSubsidySurplus + b.deductSubsidySurplus,
            deductCashSurplus: a.deductCashSurplus + b.deductCashSurplus,
            preferentialAmount: a.preferentialAmount + b.preferentialAmount,
            total: a.total + b.total
          };
        },
        {
          number: 0,
          deductSubsidySurplus: 0.0,
          deductCashSurplus: 0.0,
          preferentialAmount: 0.0,
          total: 0.0
        }
      );
    }
  }
};
</script>
