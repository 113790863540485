<template>
  <app-tabs v-model="active">
    <el-tab-pane label="消费记录" lazy>
      <record />
    </el-tab-pane>
    <el-tab-pane label="消费统计" lazy>
      <stats />
    </el-tab-pane>
  </app-tabs>
</template>

<script>
import record from './record';
import stats from './stats';

export default {
  components: { record, stats },
  name: 'ConsumeStatistics',
  data() {
    return {
      active: '0'
    };
  }
};
</script>
