<template>
  <app-page :toolbar="toolbar" :list="list" @loaded="onLoaded">
    <template #append>
      <el-alert
        class="alert"
        title="数据量大，建议选择日期范围查询"
        type="warning"
        :closable="false"
      />
    </template>
  </app-page>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'Record',
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '80px',
        igrone: ['canteenType'],
        model: {
          name: undefined,
          departmentId: undefined,
          telephone: undefined,
          policeCard: undefined,
          consumerGroupId: undefined,
          canteenType: Number(Number(sessionStorage.getItem('canteenType')) !== 999 ? sessionStorage.getItem('canteenType') : 1),
          startDate: dayjs()
            .startOf('month')
            .format('YYYY-MM-DD'),
          endDate: dayjs()
            .endOf('month')
            .format('YYYY-MM-DD'),
          type: undefined
        },
        fields: [
          {
            label: '姓名',
            prop: 'name',
            placeholder: '请输入要搜索的姓名',
            clearable: true
          },
          {
            label: '部门',
            prop: 'departmentId',
            placeholder: '请选择要搜索的部门',
            type: 'cascader',
            clearable: true,
            collapseTags: true,
            filterable: true,
            props: {
              expandTrigger: 'hover',
              multiple: true,
              label: 'name',
              value: 'id'
            },
            options: [],
            url: '/department/getList',
            method: 'post'
          },
          {
            label: '电话',
            prop: 'telephone',
            placeholder: '请输入要搜索的电话',
            clearable: true
          },
          {
            label: '警号',
            prop: 'policeCard',
            placeholder: '请输入要搜索警号',
            clearable: true
          },
          {
            label: '分组',
            prop: 'consumerGroupId',
            placeholder: '请选择要搜索分组',
            type: 'select',
            clearable: true,
            options: [],
            url: '/canteenConsumerGroup/dropDownList',
            mapper(item) {
              return {
                label: item.name,
                value: item.id
              };
            }
          },
          {
            label: '食堂',
            prop: 'canteenType',
            placeholder: '请选择要搜索食堂',
            type: 'select',
            // clearable: true,
            options: [
              { label: '全部', value: undefined },
              { label: '一食堂', value: 1 },
              { label: '二食堂', value: 2 }
            ]
          },
          {
            label: '查询日期',
            prop1: 'startDate',
            prop2: 'endDate',
            type: 'daterange',
            valueFormat: 'yyyy-MM-dd',
            format: 'yyyy-MM-dd',
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期',
            pickerOptions: {
              firstDayOfWeek: 1
            }
          },
          {
            label: '消费类型',
            prop: 'type',
            placeholder: '请选择要搜索的类型',
            type: 'select',
            clearable: true,
            options: [
              { label: '全部', value: undefined },
              { label: '补贴消费', value: 0 },
              { label: '现金消费', value: 1 },
              { label: '混合消费', value: 2 }
            ]
          }
        ],
        downloader: {
          text: '导出',
          url: '/canteen/expensesRecord/expensesRecordExport'
        }
      },
      list: {
        mode: 'new',
        method: 'post',
        url: '/canteen/expensesRecord',
        fields: [
          {
            label: '姓名',
            prop: 'sysUserName',
            width: 240,
            headerAlign: 'center',
            showOverflowTooltip: true
          },
          {
            label: '部门',
            prop: 'sysDepartmentName',
            headerAlign: 'center',
            showOverflowTooltip: true,
            formatter(row) {
              return row.sysDepartmentName?.split('、')[0];
            }
          },
          {
            label: '扣费时间',
            prop: 'createTime',
            width: 230,
            headerAlign: 'center'
          },
          {
            label: '分组',
            prop: 'canteenConsumerGroupName',
            width: 80,
            align: 'center',
            headerAlign: 'center'
          },
          {
            label: '消费类型',
            prop: 'type',
            width: 120,
            align: 'center',
            headerAlign: 'center',
            colors: ['danger', 'warning', 'primary'],
            formatter(row) {
              const values = ['补贴消费', '现金消费', '混合消费'];
              return values[row.type];
            }
          },
          {
            label: '消费金额',
            prop: 'amount',
            width: 80,
            align: 'right',
            headerAlign: 'center',
            color: 'primary',
            formatter(row) {
              return `￥${row.amount}`;
            }
          },
          {
            label: '扣除补贴',
            prop: 'deductSubsidySurplus',
            width: 80,
            align: 'right',
            headerAlign: 'center',
            color: 'danger',
            formatter(row) {
              return `￥${row.deductSubsidySurplus}`;
            }
          },
          {
            label: '扣除现金',
            prop: 'deductCashSurplus',
            width: 80,
            align: 'right',
            headerAlign: 'center',
            color: 'danger',
            formatter(row) {
              return `￥${row.deductCashSurplus}`;
            }
          },
          {
            label: '优惠金额',
            prop: 'preferentialAmount',
            width: 80,
            align: 'right',
            headerAlign: 'center',
            color: 'success',
            formatter(row) {
              return `￥${row.preferentialAmount}`;
            }
          },
          {
            label: '补贴余额',
            prop: 'subsidySurplus',
            width: 140,
            align: 'right',
            headerAlign: 'center',
            formatter(row) {
              return `￥${row.subsidySurplus}`;
            }
          },
          {
            label: '现金余额',
            prop: 'cashSurplus',
            width: 120,
            align: 'right',
            headerAlign: 'center',
            formatter(row) {
              return `￥${row.cashSurplus}`;
            }
          }
        ],
        action: false
      }
    };
  },
  methods:{
    onLoaded(){
      if (sessionStorage.getItem('canteenType')){
        if (Number(sessionStorage.getItem('canteenType')) === 1){
          this.toolbar.fields[5].options = [{ label: '一食堂', value: 1 }]
        }else if (Number(sessionStorage.getItem('canteenType')) === 2){
          this.toolbar.fields[5].options = [{ label: '二食堂', value: 2 }]
        }
      }
    }
  }
};
</script>
